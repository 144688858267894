<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Thống kê tài khoản</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Thống kê tài khoản</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Nền tảng</label>
                  <select class="form-select" v-model="provider" @change="getData">
                    <option value="">Tất cả</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                    <option value="tiktok">Tiktok</option>
                  </select>
                </div>
                <form @submit.prevent="getData" class="col-12 col-sm-6 col-md-3 mb-3">
                  <div class="form-group">
                    <label for="">Tìm theo Username</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập Username"
                      v-model="username"
                    />
                  </div>
                </form>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Ngày</label>
                  <date-range-picker
                    ref="picker"
                    @update="getData"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :autoApply="true"
                    v-model="dateRange"
                    opens="right"
                    class="issue__filter__time_picker w-100"
                    custom-range-label="Chọn ngày bắt đầu - kết thúc"
                    :show-ranges="true"
                  />
                </div>

                <loading-vue v-if="loading" />

                <div class="row min-vh-100">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">Thống kê theo ngày</h4>
                        <div class="table-responsive">
                          <table class="table align-middle table-nowrap mb-0">
                            <thead class="table-light">
                              <tr>
                                <th class="align-middle">Ngày</th>
                                <th class="align-middle">Username</th>
                                <th class="align-middle">Nền Tảng</th>
                                <th class="align-middle">Tổng UIDs đã làm</th>
                                <th class="align-middle">Số Jobs đã làm</th>
                                <th class="align-middle">Số tiền đã làm</th>
                              </tr>
                            </thead>
                            <tbody v-if="!loading">
                              <tr v-for="(value, key) in data" :key="key">
                                <td>{{ value.date }}</td>
                                <td>
                                  <router-link
                                    :to="`/statistics/by-username-detail?username=${value.username}&date_from=${value.date}&date_to=${value.date}`"
                                    >{{ value.username }}</router-link
                                  >
                                </td>
                                <td>{{ value.provider }}</td>
                                <td>{{ value.total_uid }}</td>
                                <td>{{ value.total_jobs | formatNumber }}</td>
                                <td>{{ value.total_coin | formatNumber }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                    </div>
                  </div>
                </div>
                <!--end row-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import statisticsApi from "@/api/statistics";
import LoadingVue from "@/components/loading/Loading.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import { buildParamQuery } from "@/helpers";

export default {
  name: "StatisticsUsername",
  components: {
    LoadingVue,
    DateRangePicker,
  },
  data() {
    return {
      data: {},
      loading: false,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      username: "",
      provider: "facebook",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = {
        date_from: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        provider: this.provider,
        username: this.username,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await statisticsApi.byUsername(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.data = response.data || {};
        } else {
          this.$toastr.error(response?.message || "Tải danh sách không thành công !");
          this.data = {};
        }
      } else {
        this.$toastr.error("Tải danh sách không thành công !");
        this.data = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
